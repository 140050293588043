import './App.css';
import { Route, Routes} from 'react-router-dom';

function App() {
  let url = `https://unisource.3plsystems.com${window.location.pathname}`;
  window.history.pushState(null, '', '/'); 
  const frame = () => (<iframe title="frame" src={url} frameBorder="0" scrolling="yes" seamless="seamless" />)
  return (
    <div>
      <Routes>
        <Route path='/' element={frame()} />
        <Route path='*' element={frame()} />
      </Routes>

    </div>
  );
}

export default App;
